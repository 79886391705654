@import '../../assets/styles/variables',
  '../../assets/styles/mixins';

////////////////////////
// FORMS
////////////////////////
input,
textarea,
select,
.react-datepicker-ignore-onclickoutside {
  background: transparent url('../images/inputWrong.png') no-repeat right;
  background-position-y: 38px;
  border: solid 0 $grey-4;
  border-bottom: solid 1px $grey-7;
  color: $grey-1;
  font-size: 14px;
  padding: 8px 0;
  position: relative;
  transition: background-position-y $animation-short ease-in,
    background-size $animation-short ease-in;
  width: 100%;

  &[type=date],
  &[type=datetime-local] {
    appearance: unset;
    background-image: url('../images/calendarDark.svg');
    background-position: 100% center;
    background-repeat: no-repeat;

    &.filled {
      background-image: url('../images/menuCross.svg');
    }

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }
  }

  &.filter {
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
    height: 55px;
    padding: 18px 20px;

    &.big {
      @include boxShadow; 
      background-color: white;
    }

    &.search {
      background-image: url('../images/search.svg');
    }

    &[type=date] {
      background-image: url('../images/calendar.svg');

      &.filled {
        background-image: url('../images/menuCross.svg');
      }
    }

  }


  &::placeholder {
    color: $grey-4;
    text-overflow: ellipsis;
  }
}

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    background: transparent url('../images/calendarDark.svg') no-repeat 100%;
  }
}

.react-datepicker__time-container {
  width: unset;
  width: 94px !important;

  .react-datepicker__time .react-datepicker__time-box {
    width: unset !important;

    .react-datepicker__time-list {
      height: 220px !important;
    }

    ul.react-datepicker__time-list {
      width: 100px;
      height: 196px;
      overflow-x: hidden;
    }
  }
}

textarea {
  padding: 8px;
  background-position-y: calc(100% + 20px);
  resize: vertical;
}

select {
  appearance: none;
  background-image: url('../images/arrowDown.svg');
  background-position: 100% center;
  background-repeat: no-repeat;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  &>option {
    color: $BPI-Blue;

    &[data-disabled=true] {
      color: $grey-4;
    }
  }
}

input {

  &[type=file] {
    display: none;
  }

  &[type=checkbox]:not(.toggle) {
    background: transparent;
    border-bottom: 0;
    display: inline-block;
    height: 20px;
    position: relative;
    vertical-align: middle;
    width: 20px;

    &::before {
      border: solid 2px $grey-7;
      border-radius: 2px;
      display: inline-block;
      content: '';
      height: 20px;
      position: absolute;
      width: 20px;
    }

    &:checked::before {
      background: $BPI-Green url('../images/checkmark.svg') 2px center no-repeat;
      border-color: $BPI-Green;
    }
  }

  &[type='radio'] {
    appearance: none;
    background: transparent;
    border-bottom: 0;
    display: inline-block;
    height: 20px;
    margin: 0 8px 0 0;
    position: relative;
    vertical-align: baseline;
    width: 20px;

    &::before {
      border: solid 2px $grey-3;
      border-radius: 10px;
      content: '';
      display: inline-block;
      height: 20px;
      position: absolute;
      top: 2px;
      width: 20px;
    }

    &:checked::before {
      background: $BPI-Green;
      background-image: none;
      border-color: $BPI-Green;
      box-shadow: 0 0 0 3px inset white;
    }
  }

  &[type=search] {
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      background: url('../images/menuCross.svg') no-repeat 100% center;
      cursor: pointer;
      height: 1em;
      width: 1em;
    }
  }

  *:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: $grey-1;
  }
}


fieldset {
  border: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 64px;
  padding: 0;
  width: 100%;

  &.editor {
    border: unset;
    display: block;
    flex-direction: unset;
    flex-wrap: unset;
    justify-content: unset;
    margin: 0 0 128px;
    padding: 0;
  }

  &.inlineCheckboxes {
    width: 100%;
    display: block;
  }

  legend {
    color: black;
    font-weight: 600;
    font-size: 14px;
  }

  p.legendSubtitle{
    color: $grey-1;
    flex-grow: 1;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 0 2px;
    width: 100%;
  }

  label,
  .labelLike {
    width: calc(50% - 16px);
    flex-wrap: nowrap;
    flex-shrink: 1;
    flex-grow: 0;

    &.fullWidth {
      width: 100%;
    }

    &.inline {
      width: 100%;
      display: block;

      input:not(:nth-child(2)) {
        margin-left: 12px;
      }
    }

    &.quarter {
      width: calc(25% - 24px);
    }

    &.threeQuarters {
      width: 75%;
    }

    &.sixth{
      width: calc( 100% / 6 - 24px);
    }

    &.file {
      cursor: pointer;

      &:hover {
        button {
          background-color: $BPI-Yellow;

          color: white svg {
            fill: white;
          }
        }
      }
    }

    &.noLabel {
      &>p:first-child {
        visibility: hidden;
      }
    }

    &.rightAligned {
      margin: 8px 0 0 auto;

      button {
        margin: 0 0 0 auto;
      }
    }

    &.fieldTags {
      margin: 8px 0 0 0;
      display: block;
      width: calc(75%);
    }

    &.minimal{
      input, select{
        width: max-content;
      }
    }

  }

}

button.uploader {
  // for input image and files
  cursor: pointer;
  margin: 16px 0 0;
  pointer-events: none;
}

label,
.labelLike {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 32px 0 0;
  position: relative;
  text-align: left;


  &.high {
    justify-content: flex-start;
  }

  &.error {

    &>input,
    &>textarea,
    &>*:-internal-autofill-selected {
      background-position-y: 16px;
      background-size: 16px 14px;

    }
  }

  &>p:first-child {
    color: $grey-2;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;

    &.required {
      &::after {
        color: $Red;
        content: '*'
      }
    }
  }

  &>p.error {
    top: 100%;
    color: $Red;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    opacity: 0;
    position: absolute;
    transition: opacity $animation-short ease-in;

    &.active {
      animation-fill-mode: backwards;
      opacity: 1;
    }
  }

  &>ul {
    height: 256px;
    width: 100%;
    overflow: auto;
  }
}

.inputIcon {
  position: relative;
  max-width: 193px;
  width: 100%;

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
}

////////////////////////
// ENS FORMS
////////////////////////